import { get } from 'lodash';
import { Typography } from '@mui/material';

const paymentEnvironmentMap = {
  internet: 'Internet Website',
  'mail-or-phone': 'Mail/Phone Order',
  retail: 'Retail',
  'retail-with-tip': 'Retail with Tip',
  service: 'Service',
  restaurant: 'Restaurant',
  'fast-food': 'Fast Food',
  lodging: 'Lodging',
  petrolium: 'Petrolium',
};

export const accountInformation = [
  //  add this row to the table.
  {
    label: 'Merchant ID',
    //  grabs the value for id from the dataset
    valueGetter: (d) => `${get(d, 'id')}`,
  },
  {
    label: 'Name',
    valueGetter: (d) => `${get(d, 'name')}`,
  },
  {
    label: 'Cardholder statement descriptor',
    valueGetter: (d) => `${get(d, 'cardName')}`,
  },
  {
    label: 'Customer service number',
    valueGetter: (d) => `${get(d, 'customerServiceNumber')}`,
  },
  {
    label: 'Intacct Customer ID',
    valueGetter: (d) => `${get(d, 'intacctCustomerId')}`,
  },
  {
    label: 'Payment environment',
    valueGetter: (d) => `${paymentEnvironmentMap[get(d, 'paymentEnvironment')]}`,
  },
  {
    label: 'Sale average ($)',
    valueGetter: (d) => `${get(d, 'saleAverage')}`,
  },
  {
    label: 'Monthly average ($)',
    valueGetter: (d) => `${get(d, 'monthlyAverage')}`,
  },
  {
    label: 'Swipe/Chip (CP) %',
    valueGetter: (d) => `${get(d, 'cpSwipeChip')}`,
  },
  {
    label: 'Keyed (CP) %',
    valueGetter: (d) => `${get(d, 'cpKeyed')}`,
  },
  {
    label: 'Keyed (CNP) %',
    valueGetter: (d) => `${get(d, 'cnpKeyed')}`,
  },
  {
    label: 'Services description',
    valueGetter: (d) => `${get(d, 'servicesOrSalesDescription')}`,
  },
  {
    label: 'Comments',
    valueGetter: (d) => `${get(d, 'comments')}`,
  },
];

export const addressInformation = [
  {

    label: 'Physical',
    valueGetter: (data) => {
      const { location } = data;
      return (
        <>
          <Typography color="textSecondary" variant="body2">
            {location?.address1}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {location?.address2}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {`${location?.city}, ${location?.state} ${location?.postalCode}`}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {location?.country}
          </Typography>
        </>
      );
    },
  },
  {
    label: 'Mailing',
    valueGetter: (data) => {
      const { mailing } = data;
      return (
        <>
          <Typography color="textSecondary" variant="body2">
            {mailing?.address1}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {mailing?.address2}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {`${mailing?.city}, ${mailing?.state} ${mailing?.postalCode}`}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {mailing?.country}
          </Typography>
        </>
      );
    },
  },
];

const sitetypeMap = {
  'shopping-center': 'Shopping Center',
  'office-building': 'Office Building',
  'separate-building': 'Separate Building',
  residence: 'Residence',
};

const footageMap = {
  '0-500': '0 - 500',
  '501-2500': '501 - 2500',
  '2501-5000': '2501 - 5000',
  '5001-10000': '5001 - 10000',
  '10000+': '10000+',
};

const zoningMap = {
  commercial: 'Commercial',
  industrial: 'Industrial',
  residential: 'Residential',
};

const deedMap = {
  own: 'Own',
  rental: 'Rental',
};

export const siteInspection = [
  {
    label: 'Type of building',
    valueGetter: (data) => `${sitetypeMap[get(data, 'location.type')]}`,
  },
  {
    label: 'Footage',
    valueGetter: (data) => `${footageMap[get(data, 'location.footage')]}`,
  },
  {
    label: 'Zoning',
    valueGetter: (data) => `${zoningMap[get(data, 'location.zoning')]}`,
  },
  {
    label: 'Deed',
    valueGetter: (data) => `${deedMap[get(data, 'location.deed')]}`,
  },
];

const integratedMap = {
  'eagle-recorder': 'Eagle Recorder',
  'eagle-treas-tax': 'Eagle Treasurer/Tax',
  'incode-cash-10': 'Incode - Cash Collections 10',
  'incode-901-cash': 'Incode9.01 - Cash Collections',
  'lgd-taxwise': 'LGD - TaxWise',
  'munis-ivr': 'Munis IVR',
  'munis-payment': 'Munis Payment Processing',
  'new-world-erp': 'New World ERP',
  'tyler-cashiering': 'Tyler Cashiering',
  'tyler-parks-rec': 'Tyler Parks & Rec',
  'cash-receipts-z11': 'Cash Receipts - Z11',
  'cash-receipts-z9': 'Cash Receipts - Z9',
  'one-point-pos': 'One Point POS',
  'adcomp-systems': 'AdComp Systems',
  'amano-mcgann': 'Amano McGann Parking Solutions',
  'bsa-software': 'BS&A Software',
  'bypass-pos': 'Bypass POS',
  'civic-rec': 'CivicRec',
  'club-prophet': 'Club Prophet',
  comprise: 'Comprise',
  'envision-ware': 'EnvisionWare',
};

const nonIntegratedMap = {
  'authorize-net': 'Authorize.net',
  'dejavoo-z11': 'Dejavoo Z11',
  'dejavoo-z9': 'Dejavoo Z9',
  'equinox-apollo-aio': 'Equinox Apollo AIO',
  'ingenico-ict250': 'Ingenico iCT250',
  'omni-vx520': 'Omni Vx520',
  'pax-a80-bridgepay': 'PAX A80 - BridgePay',
  'pax-sp30': 'PAX SP30',
  'pay-guardian': 'Pay Guardian',
  'tyler-parks-rec': 'Tyler Parks & Rec',
  usaepay: 'USAePay',
  'vital-pos': 'VitalPOS',
};

export const paymentSoftware = [
  {
    label: 'Application Version',
    valueGetter: (data) => `${get(data, 'applicationVersion')}`,
  },
  {
    label: 'Integrated app',
    valueGetter: (data) => `${integratedMap[get(data, 'integratedApp')]}`,
  },
  {
    label: 'Non-integrated',
    valueGetter: (data) => `${nonIntegratedMap[get(data, 'nonIntegrated')]}`,
  },
];

export const managerInfo = [
  {
    label: 'First Name',
    valueGetter: (data) => `${get(data, 'firstName')}`,
  },
  {
    label: 'Last Name',
    valueGetter: (data) => `${get(data, 'lastName')}`,
  },
  {
    label: 'Professional Title',
    valueGetter: (data) => `${get(data, 'professionalTitle')}`,
  },
  {
    label: 'Email Address',
    valueGetter: (data) => `${get(data, 'email')}`,
  },
  {
    label: 'Phone Number',
    valueGetter: (data) => `${get(data, 'phone')}`,
  },
  {
    label: 'Mobile Phone Number',
    valueGetter: (data) => `${get(data, 'mobile')}`,
  },
];
