import ErrorFactory from '../../shared/errors';
import httpClient from '../httpClient';

export async function ApplicationManagerContactListRequest({ queryKey }) {
  try {
    const [, params] = queryKey;
    const response = await httpClient.get('/merchants', { params });

    const responseMerchants = response?.data?.merchants;

    const applicationManagers = responseMerchants?.map((merchant) => merchant?.applicationManager);

    const appManagerResponse = [...new Map(applicationManagers.map((v) => [v.firstName && v.lastName, v])).values()];

    return appManagerResponse;
  } catch (err) {
    throw ErrorFactory(err);
  }
}
