import { Autocomplete, TextField } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useState } from 'react';

import { ApplicationManagerContactListRequest } from '../../../../../api/Contacts/List';

const queryKey = 'projectManager-list-selector';

const useAppManagerQuery = ({ enabled, onSuccess = () => null }) => {
  const appManagerQuery = useQuery([queryKey, { searchExact: 'true', searchField: 'type', searchString: 'application_manager' }], ApplicationManagerContactListRequest, {
    refetchOnWindowFocus: false, cacheTime: 300000, enabled, onSuccess,
  });
  return appManagerQuery;
};

export function AppManagerSelector({ value, setValue = () => {} }) {
  const [inputValue, setInputValue] = useState('');
  const appManagerQuery = useAppManagerQuery({ enabled: true });

  const options = appManagerQuery?.data || [];

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue, reason) => {
        if (newValue != null) {
          setValue(newValue, reason);
        } else {
          setValue({}, reason);
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="controllable-states-demo"
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.firstName && option.lastName) {
          return `${option.firstName} ${option.lastName}`;
        }
        return '';
      }}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Application Manager" />}
    />
  );
}
