import { useMemo, useState, useEffect } from 'react';
import { experimentalStyled } from '@mui/material';
import MuiGrid from '@mui/material/Grid';
import { useDispatch } from 'react-redux';

import { useController } from 'react-hook-form';
import { RowTable } from '../RowTable';

import './styles.css';
import { fetchMID } from '../../redux/slices/merchants';
import usePermissions from '../../IAM/usePermissions';
import MutationAccordion from '../shared/Accordion/MutationAccordion';
import SiteInspection from '../forms/SiteInspection';
import ContactInfo from '../forms/ContactInfo';
import EditMerchantRequest from './edit/EditMerchantRequest';
import AccountInformationForm from './edit/AccountInformationForm';
import AddressInformationForm from './edit/AddressInformationForm';
import PaymentIntegrationForm from './edit/PaymentIntegrationForm';

import {
  accountInformation, addressInformation, paymentSoftware, siteInspection,
} from './row_tables';
import { ValidationSchema, FormFields } from './edit/Schema';
import { ACTIONS, RESOURCES } from '../../IAM/configuration';
import { AppManagerSelector } from '../accounts/mids/add/sections/AppManagerSelector';

const Grid = experimentalStyled(MuiGrid)(() => ({
  marginBottom: 10,
}));

const toAccordionForm = (Component, name, validate) => ({ control }) => (
  <Grid
    container
    spacing={1}
    sx={{ py: 1 }}
  >
    <Component control={control} name={name} validate={validate} />
  </Grid>
);

const AccountManagerForm = toAccordionForm(ContactInfo, FormFields.ACCOUNT_MANAGER, true);
const SiteInspectionForm = toAccordionForm(SiteInspection, FormFields.LOCATION, true);

const Overview = ({ merchant }) => {
  const permissions = usePermissions();
  const dispatcher = useDispatch();
  const applicationManager = merchant?.applicationManager || {};
  const accountManager = merchant?.accountManager || {};
  const accordionCommonProps = useMemo(() => {
    return {
      request: EditMerchantRequest(permissions.merchants.editMerchantSensitive()),
      successText: 'Merchant has been successfully updated',
      validationSchema: ValidationSchema,
      onMutated: (mutation) => {
        fetchMID({ id: mutation?.id })(dispatcher);
      },
      payload: {
        ...merchant,
        companyID: `${merchant?.companyID}`,
        comments: merchant?.comments || null,
        [FormFields.ACCOUNT_MANAGER]: {
          ...merchant[FormFields.ACCOUNT_MANAGER],
          confirmEmail: merchant[FormFields.ACCOUNT_MANAGER]?.email,
        },
        [FormFields.LOCATION]: {
          ...merchant?.location,
          address2: merchant?.location?.address2 || null,
        },
        [FormFields.MAILING]: {
          ...merchant?.location,
          address2: merchant?.location?.address2 || null,
        },
        [FormFields.APPLICATION_MANAGER]: {
          ...merchant[FormFields.APPLICATION_MANAGER],
          confirmEmail: merchant[FormFields.APPLICATION_MANAGER]?.email,
        },
      },
    };
  }, [merchant]);

  const toAppManagerAccordionForm = (name, validate) => ({ control }) => {
    const [appManager, setAppManager] = useState(applicationManager);

    const {
      field: manager,
    } = useController({
      name: `${name}`,
      control,
    });

    const {
      field: id,
    } = useController({
      name: `${name}.id`,
      control,
    });

    const {
      field: firstName,
    } = useController({
      name: `${name}.firstName`,
      control,
    });

    const {
      field: lastName,
    } = useController({
      name: `${name}.lastName`,
      control,
    });

    const {
      field: professionalTitle,
    } = useController({
      name: `${name}.professionalTitle`,
      control,
    });

    const {
      field: email,
    } = useController({
      name: `${name}.email`,
      control,
    });

    const {
      field: confirmEmail,
    } = useController({
      name: `${name}.confirmEmail`,
      control,
    });

    const {
      field: phone,
    } = useController({
      name: `${name}.phone`,
      control,
    });

    const {
      field: mobile,
    } = useController({
      name: `${name}.mobile`,
      control,
    });

    useEffect(() => {
      if (appManager?.firstName && appManager?.lastName && appManager?.email && appManager?.phone) {
        manager.onChange({ ...appManager, confirmEmai: appManager.email });
        id.onChange(appManager.ID);
        firstName.onChange(appManager.firstName);
        lastName.onChange(appManager.lastName);
        professionalTitle.onChange(appManager.professionalTitle);
        email.onChange(appManager.email);
        confirmEmail.onChange(appManager.email);
        phone.onChange(appManager.phone);
        mobile.onChange(appManager.mobile);
      } else {
        manager.onChange({});
        firstName.onChange('');
        lastName.onChange('');
        professionalTitle.onChange('');
        email.onChange('');
        confirmEmail.onChange('');
        phone.onChange('');
        mobile.onChange('');
      }
    }, [appManager]);

    return (
      <>
        <Grid
          container
          spacing={1}
          sx={{ py: 1 }}
        >
          <div style={{ marginTop: '5px' }}>
            <AppManagerSelector value={appManager} setValue={(value) => setAppManager(value)} />
          </div>
          <Grid
            container
            spacing={1}
            sx={{ py: 1 }}
          >
            <ContactInfo control={control} name={name} validate={validate} />
          </Grid>
        </Grid>
      </>
    );
  };

  const ApplicationManagerForm = toAppManagerAccordionForm(FormFields.APPLICATION_MANAGER, true);

  return (
    <>
      <Grid container direction="row">
        <MutationAccordion
          expanded
          disableEdit={!permissions.hasPermission([RESOURCES.MERCHANTS_UPDATE, ACTIONS.UPDATE])}
          title="Merchant Information"
          content={(
            <RowTable
              data={merchant}
              rows={accountInformation}
            />
          )}
          FormComponent={AccountInformationForm}
          {...accordionCommonProps}
        />
        <MutationAccordion
          title="Address Information"
          disableEdit={!permissions.merchants.editMerchantSensitive()}
          content={(
            <RowTable
              data={merchant}
              rows={addressInformation}
            />
          )}
          FormComponent={AddressInformationForm}
          {...accordionCommonProps}
        />
        <MutationAccordion
          title="Account Manager"
          disableEdit={!permissions.hasPermission([RESOURCES.MERCHANTS_UPDATE, ACTIONS.UPDATE])}
          content={(
            <RowTable
              data={accountManager}
              autoGenerateRows
            />
          )}
          FormComponent={AccountManagerForm}
          {...accordionCommonProps}
        />
        <MutationAccordion
          title="Application Manager"
          disableEdit={!permissions.hasPermission([RESOURCES.MERCHANTS_UPDATE, ACTIONS.UPDATE])}
          content={(
            <>
              <RowTable
                data={applicationManager}
                autoGenerateRows
              />
            </>
          )}
          FormComponent={(
            ApplicationManagerForm
          )}
          {...accordionCommonProps}
        />
        <MutationAccordion
          title="Site Inspection"
          disableEdit={!permissions.merchants.editMerchantSensitive()}
          content={(
            <RowTable
              data={merchant}
              rows={siteInspection}
            />
          )}
          FormComponent={SiteInspectionForm}
          {...accordionCommonProps}
        />
        <MutationAccordion
          disableEdit={!permissions.hasPermission([RESOURCES.MERCHANTS_UPDATE, ACTIONS.UPDATE])}
          title="Payment/Software Integration"
          content={(
            <RowTable
              data={merchant}
              rows={paymentSoftware}
            />
          )}
          FormComponent={PaymentIntegrationForm}
          {...accordionCommonProps}
        />
      </Grid>
    </>
  );
};

export default Overview;
